import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import Project from "./components/reports/project/Project";
import ReportDetails from "./components/reports/project/report-details/ReportDetails";
import Projects from "./components/reports/Projects";
import ResetPassword from "./components/reset-password/ResetPassword";
import Settings from "./components/settings/Settings";

const Router = ({ jwt, user }) => {
  const location = useLocation();

  return (
    <Routes>
      {location.pathname !== "/login" && !jwt ? (
        <>
          <Route path="/*" element={<Navigate replace to="/login" />} />
        </>
      ) : undefined}

      <Route path="/login" element={<Login jwt={jwt} />}></Route>

      {user?.requirePasswordReset ? (
        <Route path="/*" element={<Navigate replace to="/reset-password" />} />
      ) : undefined}

      <Route path="/reset-password" element={<ResetPassword />}></Route>

      {jwt && !user?.requirePasswordReset ? (
        <>
          <Route path="/" element={<Home />}>
            <Route path="projects" element={<Projects />}></Route>
            <Route path="projects/:projectId" element={<Project />} />
            <Route
              path="projects/:projectId/:reportId"
              element={<ReportDetails />}
            />
            <Route path="/settings" element={<Settings />} />
          </Route>
        </>
      ) : undefined}
    </Routes>
  );
};

export default Router;
