import { useState } from "react";
import axios from "../../../axios";
import CloseSVG from "../../../assets/images/js-svg/close.svg";

import styles from "./AddUser.module.scss";

const AddUser = ({ close }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const saveUser = async () => {
    await axios.post("/user", {
      name,
      email,
    });
    close();
  };
  return (
    <div className={styles.addUser}>
      <div className={`${styles.content}`}>
        <h2 className={`ffmm fs16 df aic`}>
          Add user
          <span className={`mla`} onClick={() => close(false)}>
            <CloseSVG />
          </span>
        </h2>
        <div className={`${styles.inputWrapper}`}>
          <label className={`ffmr fs12 df aic`}>Name</label>
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={`${styles.inputWrapper}`}>
          <label className={`ffmr fs12 df aic`}>Email</label>
          <input value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <button
          onClick={saveUser}
          className={`ffmr fs16 df aic ${styles.saveBtn}`}
        >
          Save user
        </button>
      </div>
    </div>
  );
};
export default AddUser;
