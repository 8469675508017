import "./App.scss";
import Router from "./router";
import AppContext from "./context/AppContext";
import { BrowserRouter } from "react-router-dom";
import { useState } from "react";

function App() {
  const [context, setContext] = useState({
    jwt: localStorage.getItem("x-auth"),
  });
  const contextVals = { context, setContext };

  return (
    <div className="app df">
      <BrowserRouter>
        <AppContext.Provider value={[contextVals, setContext]}>
          <Router jwt={context?.jwt} user={context?.user} />
        </AppContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
