import { useState } from "react";
import axios from "../../../axios";
import CloseSVG from "../../../assets/images/js-svg/close.svg";

import styles from "./AddProject.module.scss";

const AddProject = ({ close, userId }) => {
  const [projectName, setProjectName] = useState("");
  const [projectStoryId, setProjectStoryId] = useState("");

  const saveProject = async () => {
    await axios.post("/project", {
      name: projectName,
      fullstoryId: projectStoryId,
      userId,
    });
    close();
  };
  return (
    <div className={styles.addProject}>
      <div className={`${styles.content}`}>
        <h2 className={`ffmm fs16 df aic`}>
          Add project
          <span className={`mla`} onClick={() => close(false)}>
            <CloseSVG />
          </span>
        </h2>
        <div className={`${styles.inputWrapper}`}>
          <label className={`ffmr fs12 df aic`}>Project name</label>
          <input
            value={projectName}
            onChange={(e) => {
              setProjectName(e.target.value);
            }}
          />
        </div>
        <div className={`${styles.inputWrapper}`}>
          <label className={`ffmr fs12 df aic`}>
            Project fullstory ID (like q-8U03Y6-gr2)
          </label>
          <input
            value={projectStoryId}
            onChange={(e) => setProjectStoryId(e.target.value)}
          />
        </div>

        <button
          onClick={saveProject}
          className={`ffmr fs16 df aic ${styles.saveBtn}`}
        >
          Save project
        </button>
      </div>
    </div>
  );
};
export default AddProject;
