import axios from "../../../axios";

import { useContext, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import styles from "./Project.module.scss";
import BackArrowSVG from "../../../assets/images/js-svg/back-arrow.svg";
import AppContext from "../../../context/AppContext";
import NotFound from "../../../assets/images/js-svg/not-found.svg";

const Project = (props) => {
  // eslint-disable-next-line
  const [context, setContext] = useContext(AppContext);
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const [project, setProject] = useState({});
  const [reports, setReports] = useState([]);

  const getProject = async () => {
    const projectRe = await axios.get(`/project/${params.projectId}`);
    setProject(projectRe.data);
  };

  const getReports = async () => {
    let localReports = await axios.get(`/report/${params.projectId}`);
    setReports(localReports.data);
  };

  useEffect(() => {
    getProject();
    getReports();
    // eslint-disable-next-line
  }, []);

  const getTab = () => {
    return searchParams.get("tab");
  };

  return (
    <div className={styles.project}>
      <h2 className={`ffmb fs20 ${styles.pageHeader}`}>
        <Link to={`/projects`} className={`m-r-8 ${styles.backArrow}`}>
          <BackArrowSVG fill="#686868" height="16" width="16" />
        </Link>
        {project.name}
      </h2>

      <div className={`${styles.contentWrapper}`}>
        <div className={`df ${styles.tabs}`}>
          <div
            onClick={() => {
              setSearchParams({ tab: "setup" });
            }}
            className={`ffmm fs14 ${styles.tab} ${
              getTab() === "setup" ? styles.active : ""
            }`}
          >
            Setup
          </div>
          <div
            onClick={() => {
              setSearchParams({ tab: "reports" });
            }}
            className={`ffmm fs14 ${styles.tab} ${
              getTab() === "reports" ? styles.active : ""
            }`}
          >
            Reports
          </div>
        </div>

        {getTab() === "setup" ? (
          <>
            <p className={`ffmr fs14 m-t-20`}>
              Copy the code snippet bellow and paste it into your index.html
              file right before the closing &lt;/body&gt; tag.
            </p>
            <div className={styles.setUp}>
              <pre>
                &lt;report-it data-projectid="{project._id}" data-storyid="
                {project.fullstoryId}" data-userid="
                {context?.context?.user?._id}
                "&gt;&lt;/report-it&gt;
              </pre>
              <pre>
                &lt;script
                src="https://reportitplugin.blob.core.windows.net/files/main.js"&gt;&lt;/script&gt;
              </pre>
              <pre>
                &lt;link
                href="https://reportitplugin.blob.core.windows.net/files/main.css"
                rel="stylesheet" /&gt;
              </pre>
            </div>
          </>
        ) : getTab() === "reports" ? (
          <div className={styles.reports}>
            {reports?.length ? (
              reports.map((report) => (
                <Link
                  to={report._id}
                  key={report._id}
                  className={`df aic ${styles.reportLine}`}
                >
                  <div>
                    <p className={`ffmb fs16`}>
                      {report.title ? report.title : "Dropped pin report"}
                    </p>
                    {/* <p>{report.title ? report.title : "Dropped pin report"}</p> */}
                    {/* <a href={report.recordingUrl} target="_blank">
                    Recording link
                  </a> */}
                  </div>
                  <div className={`mla`}>
                    {report?.attachments?.map((att) => (
                      <img src={att} height="80px" alt="" />
                    ))}
                  </div>
                </Link>
              ))
            ) : (
              <div
                className={`df ffc acc ${styles.noProjectsWrapper}`}
                style={{ height: "100%" }}
              >
                <NotFound fill="#062488" />
                <p className={`m-t-40 ffmr fs20`}>No projects yet</p>
                <div
                  className={`m-t-40 ffmr fs16 ${styles.setupBtn}`}
                  onClick={() => setSearchParams({ tab: "setup" })}
                >
                  Setup your project
                </div>
              </div>
            )}
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default Project;
