import axios from "../../../../axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./ReportDetails.module.scss";
import BackArrowSVG from "../../../../assets/images/js-svg/back-arrow.svg";
import { Link } from "react-router-dom";
import CloseSVG from "../../../../assets/images/js-svg/close.svg";

const ReportDetails = (props) => {
  const params = useParams();

  const [report, setReport] = useState({});
  const [selectedImageUrl, setSelectedImageUrl] = useState(undefined);

  const getReport = async () => {
    let report = await axios.get(`/report/report/${params.reportId}`);

    setReport(report.data);
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.reportDetails}>
      <h2 className={`ffmb fs20 ${styles.pageHeader}`}>
        <Link
          to={`/projects/${params.projectId}/?tab=reports`}
          className={`m-r-8 ${styles.backArrow}`}
        >
          <BackArrowSVG fill="#686868" height="16" width="16" />
        </Link>
        Report details{" "}
      </h2>

      <div className={`p-t-40 ${styles.contentWrapper}`}>
        <div className={styles.commentsSection}>
          <div className={`df ffmm fs16 ${styles.line}`}>
            <p className={`${styles.label}`}>Report title</p>
            <p className={`${styles.val}`}>
              {report.title ? report.title : "No title"}
            </p>
          </div>
          <div className={`df ffmm fs16 ${styles.line}`}>
            <p className={`${styles.label}`}>Report comment</p>
            <p className={`${styles.val}`}>
              {report.comment ? report.comment : "No comment"}
            </p>
          </div>
        </div>

        <div className={`df ffmr fs14 ${styles.line}`}>
          <p className={`${styles.label}`}>Report user ID #</p>
          <p className={`${styles.val}`}>{report.reportItUserId}</p>
        </div>
        <div className={`df ffmr fs14 ${styles.line}`}>
          <p className={`${styles.label}`}>Report pageURL</p>
          <a
            href={report.reportUrl}
            target="_blank"
            rel="noreferrer"
            className={`${styles.val}`}
          >
            {report.reportUrl}
          </a>
        </div>

        <div className={`df ffmr fs14 ${styles.line}`}>
          <p className={`${styles.label}`}>Report location</p>
          <p className={`${styles.val}`}>
            {report.reportItLocation?.location?.city},{" "}
            {report.reportItLocation?.location?.region?.name}{" "}
            {report.reportItLocation?.location?.country.name}
          </p>
        </div>

        <div className={`df ffmr fs14 ${styles.line}`}>
          <p className={`${styles.label}`}>Report device details</p>
          <p className={`${styles.val}`}>
            {report.reportItLocation?.user_agent?.name}
            {report.reportItLocation?.user_agent?.device?.name}
            {report.reportItLocation?.user_agent?.os?.name}{" "}
            {report.reportItLocation?.user_agent?.os?.version?.replace(
              /\D/g,
              ""
            )}
          </p>
        </div>

        <div className={`df aic m-t-40 ffmr`}>
          {report.recordingUrl !== "null" ? (
            <a
              className={`df acc m-r-20 fs18 ${styles.thumbnail} ${styles.recording}`}
              href={report.recordingUrl}
              target="_blank"
              rel="noreferrer"
            >
              View recording
            </a>
          ) : undefined}

          {report?.attachments?.map((att) => (
            <div
              onClick={() => {
                setSelectedImageUrl(att);
              }}
              className={styles.thumbnail}
              style={{
                backgroundImage: `url(${att})`,
              }}
            />
          ))}
        </div>
      </div>
      {report?.attachments?.length && selectedImageUrl ? (
        <div className={styles.imagePopup}>
          <div className={styles.imagePopupOverlay}></div>
          <div className={styles.imagePopupContent}>
            <div
              className={styles.closeButton}
              onClick={() => {
                setSelectedImageUrl(undefined);
              }}
            >
              <CloseSVG />
            </div>
            <img src={report?.attachments[0]} alt="" />
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export default ReportDetails;
