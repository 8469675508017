import { useEffect, useState } from "react";
import axios from "../../axios";
import AddUser from "./add-user/AddUser";

import styles from "./Settings.module.scss";

const Settings = (props) => {
  const [users, setUsers] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);

  const getUsers = async () => {
    const response = await axios.get("/user/list");
    setUsers(response.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={styles.settings}>
      <h2 className={`ffmb fs20 df aic ${styles.pageHeader}`}>
        Users{" "}
        <span
          className={`ffmr fs16 ${styles.addBtn}`}
          onClick={() => setShowAddUser(true)}
        >
          Add user
        </span>
      </h2>
      <div className={styles.users}>
        {users.map((user) => {
          return (
            <div className={`ffmr fs16 df aic ${styles.line}`}>
              <p style={{ width: "20%" }}>{user.name}</p>
              <p style={{ width: "30%" }}>{user.email}</p>
              <p style={{ width: "20%" }}>{user.admin ? "admin" : ""}</p>
            </div>
          );
        })}
      </div>

      {showAddUser ? (
        <AddUser
          close={() => {
            getUsers();
            setShowAddUser();
          }}
        />
      ) : undefined}
    </div>
  );
};

export default Settings;
