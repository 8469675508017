import { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import axios from "../../axios";
import Nav from "../nav/Nav";
import AppContext from "../../context/AppContext";

import styles from "./Home.module.scss";

const Home = (props) => {
  const [context, setContext] = useContext(AppContext);

  const getUser = async () => {
    let userData = await axios.get("/user");
    setContext({ ...context.context, user: userData.data });
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`df ${styles.page}`}>
      <Nav />
      <div className={styles.outlet}>
        {/* {localStorage.getItem("x-auth")} */}
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
