import axios from "../../axios";
import { useContext, useEffect, useState } from "react";

import AppContext from "../../context/AppContext";
import styles from "./ResetPassword.module.scss";
import { useNavigate } from "react-router";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);

  const getUser = async () => {
    let userData = await axios.get("/user");
    setContext({ ...context.context, user: userData.data });
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const save = async () => {
    let re = await axios.put(`/user/${context.context.user._id}`, {
      password: password,
      requirePasswordReset: false,
    });
    await setContext({ ...context.context, user: re.data });
    navigate("/");
  };
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <div className={`df acc ffc ${styles.resetPassword}`}>
      <div className={`df ffc m-b-15 ${styles.inputWrpr}`}>
        <label className={`ffmr fs12 m-b-4`}>Password</label>
        <input
          className={styles.input}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className={`df ffc ${styles.inputWrpr}`}>
        <label className={`ffmr fs12 m-b-4`}>Confirm Password</label>
        <input
          className={styles.input}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>

      <button className={`ffmr fs12 m-t-25 ${styles.loginBtn}`} onClick={save}>
        Save
      </button>
    </div>
  );
};

export default ResetPassword;
