import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "../../axios";
import AppContext from "../../context/AppContext";
import AddProject from "./add-project/AddProject";

import NotFound from "../../assets/images/js-svg/not-found.svg";

import styles from "./Projects.module.scss";

const Projects = (props) => {
  // eslint-disable-next-line
  const [context, setContext] = useContext(AppContext);
  const [projects, setProjects] = useState([]);
  const [showAddProject, setShowAddProject] = useState(false);
  const getProjects = async () => {
    const projectsRe = await axios.get("/project");
    setProjects(projectsRe.data);
  };
  useEffect(() => {
    getProjects();
  }, []);
  return (
    <div className={styles.projects}>
      <h2 className={`df aic ffmb fs20 ${styles.pageHeader}`}>
        Projects.
        <span
          className={`mla ffmr fs16 ${styles.addBtn}`}
          onClick={() => setShowAddProject(true)}
        >
          Add project
        </span>
      </h2>
      <div className={`df ${styles.listWrapper}`}>
        {projects?.length ? (
          projects.map((project) => (
            <Link
              to={`${project._id}/?tab=reports`}
              className={`ffmm fs16 ${styles.listItem}`}
            >
              <p>{project.name}</p>
              <p className={`fs12 m-t-8`}>
                {dayjs(project.createdAt).format("MMM/DD/YYYY")}
              </p>
            </Link>
          ))
        ) : (
          <div className={styles.noProjectsWrapper}>
            <NotFound fill="#062488" />
            <p className={`m-t-40 ffmr fs20`}>No projects yet</p>
            <div
              className={`m-t-40 ffmr fs16 ${styles.addBtn}`}
              onClick={() => setShowAddProject(true)}
            >
              Add your first project
            </div>
          </div>
        )}
      </div>

      {showAddProject ? (
        <AddProject
          userId={context?.context?.user?._id}
          close={() => {
            getProjects();
            setShowAddProject(false);
          }}
        />
      ) : undefined}
    </div>
  );
};

export default Projects;
