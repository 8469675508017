import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import AppContext from "../../context/AppContext";
import styles from "./Login.module.scss";

const Login = (props) => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [context, setContext] = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const handleSubmit = async (e) => {
    setLoginLoading(true);
    let userData = await axios
      .post("/user/login", {
        email,
        password,
      })
      .catch((e) => e);

    if (userData?.data?.token) {
      setContext({ jwt: userData.data.token });
      await localStorage.setItem("x-auth", userData.data.token);
      axios.defaults.headers.common["x-auth"] = userData.data.token;
      setLoginLoading(false);
      navigate("/projects");
    }

    if (
      userData.response?.status === 400 ||
      userData.response?.status === 401
    ) {
      setLoginLoading(false);
      setLoginError(true);
    }
  };

  return (
    <div
      className={`df acc ffc ${styles.loginPage} ${
        loginError ? styles.error : ""
      }`}
    >
      <div className={`df ffc m-b-15 ${styles.inputWrpr} `}>
        <label className={`ffmr fs12 m-b-4`}>Email</label>
        <input
          className={styles.input}
          value={email}
          onChange={(e) => {
            setLoginError(false);
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className={`df ffc ${styles.inputWrpr}`}>
        <label className={`ffmr fs12 m-b-4`}>Password</label>
        <input
          className={styles.input}
          value={password}
          onChange={(e) => {
            setLoginError(false);
            setPassword(e.target.value);
          }}
        />
      </div>

      <button
        className={`ffmr fs12 m-t-25 ${styles.loginBtn}`}
        onClick={handleSubmit}
        disabled={loginLoading || !email?.length || !password?.length}
      >
        Login
      </button>
    </div>
  );
};

export default Login;
